var GoogleMapsRender = function (options) {
  var google = options.google, mapCanvas = options.mapCanvas;
  var latlngbounds, image, lastMarker, infoWindows = [], markers = [], latLngs = [], defaultZoomLevel = 15;

  // https://makandracards.com/makandra/1395-simple_format-helper-for-javascript
  var simpleFormat = function (str) {
    str = str.replace(/\r\n?/, "\n");
    if (str.length > 0) {
      str = str.replace(/\n\n+/g, '</p><p>');
      str = str.replace(/\n/g, '<br />');
      str = '<p>' + str + '</p>';
    }
    return str;
  };

  var infoWindowContent = function (value) {
    let contentString = '<div class="map-info-window">';
    contentString += simpleFormat(value.info_window.join("\n"));
    contentString += '</div>';
    return contentString;
  };

  var closeInfoWindow = function () {
    if (lastMarker && lastMarker !== null) {
      infoWindows[lastMarker].close();
    }
  };

  var toggleContainer = function (elements, classList) {
    for (let i = 0, n = elements.length; i < n; i++) {
      elements[i].classList.toggle(classList);
    }
  };

  var addClickEvent = function (marker, map, infowindow) {
    google.maps.event.addListener(marker, 'click', function () {
      closeInfoWindow();

      if (lastMarker !== null) {
        toggleContainer(document.querySelectorAll('.map-item-' + lastMarker), 'active');
      }

      lastMarker = markers.indexOf(marker);
      toggleContainer(document.querySelectorAll('.map-item-' + lastMarker, 'active'));
      map.panTo(marker.getPosition());
      map.setZoom(18);
      infowindow.open(map, marker);
    });
  };

  var addCloseClickEvent = function (map, infowindow) {
    google.maps.event.addListener(infowindow, 'closeclick', function () {
      map.fitBounds(latlngbounds);
      toggleContainer(document.querySelectorAll('.map-item-' + lastMarker), 'active');
    });
  };

  var addZoomEvent = function (map) {
    google.maps.event.addListener(map, 'zoom_changed', function () {
      closeInfoWindow();
    });
  };

  var addMapMarker = function (map, value, index) {
    latLngs[index] = new google.maps.LatLng(value.latitude, value.longitude);

    var infowindow = new google.maps.InfoWindow({
      content: infoWindowContent(value)
    });

    var markerOptions = {
      position: latLngs[index],
      map: map,
      title: value.address,
      zIndex: index
    };

    if (image !== undefined && value.map_icon) {
      markerOptions.icon = value.map_icon;
    } else if (image !== undefined) {
      markerOptions.icon = image;
    }

    var marker = new google.maps.Marker(markerOptions);

    addClickEvent(marker, map, infowindow);
    addCloseClickEvent(map, infowindow);

    google.maps.event.addListener(infowindow, 'closeclick', function () {
      if (infoWindows.length == 1) {
        map.setZoom(defaultZoomLevel);
      }
    });

    infoWindows.push(infowindow);
    markers.push(marker);
  };

  var drawMap = function () {
    let multipleLocations = document.querySelector(mapCanvas).dataset.locations;
    let singleLoocation = document.querySelector(mapCanvas).dataset.location;
    let map = new google.maps.Map(document.querySelector(mapCanvas), mapOptions);

    var mapOptions = {
      center: new google.maps.LatLng(53.743317, -0.331004),
      zoom: 8,
      scrollwheel: false
    };

    if (multipleLocations) {
      let jsonLocations = JSON.parse(multipleLocations);
      jsonLocations.forEach(function (value, index) {
        addMapMarker(map, value, index);
      });
    } else if (singleLoocation) {
      let jsonSingleLocation = JSON.parse(singleLoocation);
      addMapMarker(map, jsonSingleLocation, 0);
    }

    latlngbounds = new google.maps.LatLngBounds();

    latLngs.forEach(function (value, index, locations) {
      latlngbounds.extend(value);
    });

    // https://stackoverflow.com/questions/2989858/google-maps-v3-enforcing-min-zoom-level-when-using-fitbounds#comment48845727_2990316
    google.maps.event.addListenerOnce(map, 'bounds_changed', function () { this.setZoom(Math.min(defaultZoomLevel, this.getZoom())); });

    map.setCenter(latlngbounds.getCenter());
    map.fitBounds(latlngbounds);

    addZoomEvent(map);
  };

  this.init = function () {
    var mapCanvasSelector = document.querySelector(mapCanvas);

    if (mapCanvasSelector === null) {
      return;
    }

    if (mapCanvasSelector.dataset.mapIcon) {
      image = mapCanvasSelector.dataset.mapIcon;
    }

    drawMap();
  };
};

export default GoogleMapsRender;
