import '../src/colorbox/src';

$(".js--colorbox-ajax").colorbox({
  iframe: true,
  height: (window.innerHeight >= 900 ? "900px" : (window.innerHeight - 100) + "px"),
  width: (window.innerWidth >= 900 ? "900px" : (window.innerWidth - 100) + "px"),
  current: "{current} of {total}",
  previous: '<',
  next: '>',
  close: 'x'
});

$(".js--colorbox-iframe").colorbox({
  iframe: true,
  width: '1200px',
  height: '900px',
  maxWidth: '95%',
  maxHeight: '80%',
  width: '60%',
  close: 'x'
});


if(window.matchMedia("(min-width: 1024px)").matches) {
  $(".js--colorbox-div").colorbox({
    height: '85%',
    width: '1200px',
    close: 'x',
    inline: true,
    href: $(this).attr('href')
  });
}

var position;

// @see https://github.com/jackmoore/colorbox/issues/624#issuecomment-49970105
$(document).on('cbox_open', function() {
  position = $("html,body").scrollTop();
  $(document.body).css('overflow','hidden');
}).on('cbox_closed', function() {
  $(document.body).css('overflow','');
});
