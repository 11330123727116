// import $ from 'jquery';

/*
$(document).on('change', '.js--submit-on-change', function () {
  $(this).closest('form').trigger('submit');
});
*/

$(document).on('change', '.js--shared-details-toggle', function () {
  if ($(this).is(':checked')) {
    var $parentFieldset = $(this).closest('.basic-form__fieldset');

    if ($parentFieldset.length) {
      var $parent = $parentFieldset;
    } else {
      var $parent = $(this).closest('.page-section__content');
    }

    $parent.find('.js--copy-field').each(function () {
      var leaderField = $(this).data('field');
      var leaderVal = $(leaderField).val();

      if ($(this).attr('id') != leaderField) {
        $(this).val(leaderVal);
      }
    });
  }
})

$(document).on('click', '.js--resources-hub-category-select-toggle', function() {
  var field = $(this).data('field');
  var value = $(this).data('value');
  var activeClass = $(this).data('active-class');

  var optionValue = $(field).find('option[value="' + value + '"]');

  console.log('option[value="' + value + '"]');
  console.log(optionValue);

  if(optionValue.is(':selected')) {
    optionValue.prop('selected', false);
    $(this).removeClass(activeClass);
  } else {
    optionValue.prop('selected', true);
    $(this).addClass(activeClass);
  }
});
