// import $ from 'jquery';

$(document).on('mouseover', '.service-team-member-wrap', function() {
  var teamMemberId = $(this).data('team-member-id');

  var $teamMember = $('.service-team-member-details-panel[data-team-member-id="' + teamMemberId + '"]');

  if (!$teamMember.hasClass('service-team-member-details-panel--active')) {
    $('.service-team-member-details-panel').removeClass('service-team-member-details-panel--active');
    $teamMember.addClass('service-team-member-details-panel--active');
    $('.service-team-member__name--active').removeClass('service-team-member__name--active');
    $(this).find('.service-team-member__name').addClass('service-team-member__name--active');
  }
});
