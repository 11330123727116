// import $ from 'jquery';

if (window.matchMedia("(min-width: 64em)").matches) {
  $(document).on('mouseover', '.js--hover-toggle', function () {
    var contentSection = $(this).data('container');
    $(this).parent().parent().find('.active').removeClass('active');
    $(this).addClass('active');
    $('.' + contentSection).siblings().hide();
    $('.' + contentSection).show();
  });
}

if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
  /*
  $(document).on('click', '.js--hover-toggle', function (e) {
    if(!$(this).data('clicked')) {
      var contentSection = $(this).data('container');
      $(this).parent().parent().find('.active').removeClass('active');
      $(this).addClass('active');
      $('.' + contentSection).siblings().hide();
      $('.' + contentSection).show();
      $(this).data('clicked', true);
      e.preventDefault();
    }
  });
  */
}

$(document).on('click', '.js--home-resources-audience', function() {
  var audiencePath = $(this).data('audience');
  $.get(audiencePath);
});
