import GoogleMapsConstructor from './constructor';
import GoogleMapsRender from './render';

var mapCanvas = '.js--map-canvas';

window.googleMapCallback = function () {
  new GoogleMapsRender({ mapCanvas: mapCanvas, google: google }).init();
};

new GoogleMapsConstructor({ mapCanvas: mapCanvas }).init();
