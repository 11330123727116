
var ToggleClass = function (cssClass = '.js--toggle-class', activeClass = 'toggled') {
  // IE support: https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
  var matchesCheck = function () {
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector;
    }
  };

  // IE support: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
  var closestCheck = function () {
    if (!Element.prototype.closest) {
      Element.prototype.closest = function (s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
          if (el.matches(s)) return el;
          el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
      };
    }
  };

  var toggleElements = function (elements, classToToggle) {
    for (let i = 0, n = elements.length; i < n; i++) {
      elements[i].classList.toggle(classToToggle);
    }
  };

  var toggleClass = function (element, event) {
    let dataAttrs = element.dataset;
    let toggledClass = (dataAttrs.bem == 'true' ? [element.classList[0], activeClass].join('--') : activeClass);

    toggleElements(document.querySelectorAll(dataAttrs.container), dataAttrs.class);
    toggleElements([element], toggledClass);

    if (dataAttrs.return) { event.preventDefault(); }
  };

  var addEventListener = function () {
    document.addEventListener('click', function (event) {
      if (event.target.matches(cssClass + ', ' + cssClass + ' *')) {
        toggleClass(event.target.closest(cssClass), event);
      }
    }, false);
  };

  this.init = function () {
    matchesCheck();
    closestCheck();
    addEventListener();
  };
};

var toggleClass = new ToggleClass('.js--toggle-class');
toggleClass.init();
